import React from 'react';
import { Select, SelectItem } from "@nextui-org/react";
import { languages } from '@/lib/i18n/language';
import I18n from '@/context/i18n'
import { useContext } from 'use-context-selector'

const ChangeLan = () => {
  const { locale, setLocaleOnClient } = useContext(I18n)
  const changeLan = (e: any) => {
    setLocaleOnClient(e.target.value);
  };

  return (
    <Select 
      label="" 
      size='sm'
      className='w-[110px]'
      selectedKeys={[locale]}
      onChange={changeLan}
    >
      {languages.map((language) => (
        <SelectItem key={language.value}>
          {language.name}
        </SelectItem>
      ))}
    </Select>
  );
};

export default ChangeLan;
